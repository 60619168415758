import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Fellows = makeShortcode("Fellows");
const Projects = makeShortcode("Projects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kernel-principles",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel Principles`}<a parentName="h1" {...{
        "href": "#kernel-principles",
        "aria-label": "kernel principles permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel's primary goal is simple: `}<strong parentName="p">{`learn together`}</strong>{`. We do this by crafting careful peer-learning environments. In creating such environments, we adhere to two principles, one for "web3" and one more personal:`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Do no harm`}</strong></p>
      <p><strong parentName="p">{`Play, infinitely`}</strong></p>
    </Process>
    <p>{`We can expand on these principles through detailing sixteen more, each one derived from one of the core enquiries in Kernel. `}</p>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h3 {...{
          "id": "️-web3-principles",
          "style": {
            "position": "relative"
          }
        }}>{`⌛️ Web3 Principles`}<a parentName="h3" {...{
            "href": "#%EF%B8%8F-web3-principles",
            "aria-label": "️ web3 principles permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <ol>
          <li parentName="ol">{`Trust is not only transactional.`}</li>
          <li parentName="ol">{`Shared truths create value.`}</li>
          <li parentName="ol">{`Learn the limits. Then you're free. `}</li>
          <li parentName="ol">{`Money means speech.`}</li>
          <li parentName="ol">{`Incentives inform everything.`}</li>
          <li parentName="ol">{`Liberate radical institutions.`}</li>
          <li parentName="ol">{`Resist censorship economically.`}</li>
          <li parentName="ol">{`Scale ability.`}</li>
        </ol>
      </Box>
      <Box mdxType="Box">
        <h3 {...{
          "id": "-personal-principles",
          "style": {
            "position": "relative"
          }
        }}>{`🌈 Personal Principles`}<a parentName="h3" {...{
            "href": "#-personal-principles",
            "aria-label": " personal principles permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <ol>
          <li parentName="ol">{`Play with pattern.`}</li>
          <li parentName="ol">{`Develop the means to mean.`}</li>
          <li parentName="ol">{`Ask better questions. `}</li>
          <li parentName="ol">{`Consider your intention first.`}</li>
          <li parentName="ol">{`Listen to and tell better stories.`}</li>
          <li parentName="ol">{`Together, individuals govern well.`}</li>
          <li parentName="ol">{`Learn how to love learning. `}</li>
          <li parentName="ol">{`Giving is sacred.`}</li>
        </ol>
      </Box>
    </Column>
    <h2 {...{
      "id": "meet-the-kernel-fellows",
      "style": {
        "position": "relative"
      }
    }}>{`Meet The KERNEL Fellows`}<a parentName="h2" {...{
        "href": "#meet-the-kernel-fellows",
        "aria-label": "meet the kernel fellows permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Fellows mdxType="Fellows" />
    </Aligner>
    <h2 {...{
      "id": "giving-first",
      "style": {
        "position": "relative"
      }
    }}>{`Giving First`}<a parentName="h2" {...{
        "href": "#giving-first",
        "aria-label": "giving first permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We are here to serve each other, because we are each others' environment. Genuine service also takes us towards the goal of any way to meaningful self knowledge, which is the realisation that there is, in truth, no otherness. `}</p>
    <p>{`We also have a common, external purpose: `}<strong parentName="p">{`to serve the continued creation of free money`}</strong>{`. In this context, Kernel is a gift. It literally began that way: Vivek set out to give back to a community which had given him a livelihood the kind of experience he had found so meaningful in his formative years. Andy set out to give Vivek the kind of educational model which would allow him to do so. And each block is carefully and intentionally crafted with all sorts of gifted people, each unique, though we all carry the desire to share our gifts with the world around us. It is the still-beating heart of an old way of being which we are slowly bringing into the digital world.`}</p>
    <p>{`We learn from each other, and we give of ourselves until self and other lose the sense of separation which so often rules our interactions. And in this is a truth which cannot be given or received, it can only be grown in your own heart as you come to trust in the inalienable value of who you already are.`}</p>
    <h2 {...{
      "id": "projects--teams",
      "style": {
        "position": "relative"
      }
    }}>{`Projects & Teams`}<a parentName="h2" {...{
        "href": "#projects--teams",
        "aria-label": "projects  teams permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Projects mdxType="Projects" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      